import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Social from '@widgets/Social'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <ContactInfo />
      </Main>
      <Sidebar>
        <Social />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
